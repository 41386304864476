<template>
  <div class="contents liveInfo">
    <div class="box one filter" v-if="visible == showType[0]">
      <div class="flexC">
        <p class="bold">{{ $t("search-keyword-title") }}</p>
        <select v-model="keywordType">
          <option value="all">{{ $t("search-keyword-all") }}</option>
          <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
          <option value="userName">{{ $t("device-data-manager-name") }}</option>
        </select>
        <input
            type="text"
            v-model="keyword"
            @keydown.enter="logSearch"
        />
        <div class="buttonWrap" style="margin-top: 0; display: flex;">
          <button class="point medium" @click="logSearch" style="margin-left: 20px; width: 120px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <!--            <div class="flex">
                    <p class="bold">{{ $t("user-data-start-time") }}</p>
                    <Datepicker
                        v-model="startYmd"
                        :language="ko"
                        :format="customFormatter"
                        placeholder="YYYY-MM-DD"
                    ></Datepicker>
                    <span>~</span>
                    <Datepicker
                        v-model="endYmd"
                        :language="ko"
                        :format="customFormatter"
                        placeholder="YYYY-MM-DD"
                    ></Datepicker>
                  </div>-->
    </div>
    <div class="box one filter" v-if="visible == showType[1]">
      <div class="flex">
        <p class="bold">{{ $t("search-keyword-title") }}</p>
        <select v-model="keywordType">
          <option value="all">{{ $t("search-keyword-all") }}</option>
          <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
          <option value="userName">{{ $t("device-data-manager-name") }}</option>
        </select>
        <input
            type="text"
            v-model="keyword"
            @keydown.enter="logSearch"
        />
        <div class="buttonWrap" style="margin-top: 0; display: flex;">
          <button class="point medium" @click="logSearch" style="margin-left: 20px; width: 120px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("user-data-start-time") }}</p>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
        <span>~</span>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
    </div>

    <div class="tabBox recode" >
      <ul class="tabSecond2 flex">
        <li :class="{ active: visible == showType[0] ? true : false }">
          <a class="point medium" @click="handleTab(showType[0])">
            {{ $t("site-monitoring-mem-tab-list") }}
          </a>
        </li>
        <li :class="{ active: visible == showType[1] ? true : false }">
          <a class="medium" @click="handleTab(showType[1])">
            {{ $t("site-monitoring-mem-tab-log") }}
          </a>
        </li>
      </ul>
      <!--    멤버 목록 -->
      <div class="contents" v-if="visible == showType[0]" style="padding:0;">
        <div class="one">
          <div class="boxCol2-2 flexB" >
            <div class="tableWrap box" style="width: calc(70% - 2px); height: 100%; min-height: 480px; border: none; padding: 0">
              <div class="tableBox">
                <div style="min-height: 453px;">
                  <table style="table-layout: fixed;">
                    <colgroup>
                      <col style="width: 5%;"/>
                      <col style="width: 30%;"/>
                      <col style="width: 25%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 10%;"/>
                    </colgroup>
                    <tr>
                      <th>No</th>
                      <th>{{ $t("user-data-status") }}</th><!--디바이스명-->
                      <th>{{ $t("user-data-dept-name") }}</th><!--소속-->
                      <th>{{ $t("user-data-name") }}</th><!--조종사-->
                      <th>{{ $t("user-data-phone-num") }}</th><!--연락처-->
                      <th>{{ $t("user-data-check-in-num") }}</th>
                    </tr>
                    <tr v-if="visibleTotal == 0">
                      <td colspan="6" style="text-align: center;">
                        {{ $t("search-not-found") }}
                      </td>
                    </tr>
                    <tr v-for="(data, i) in visibleList" :key="'workerJoin'+i" @click="handleSelectEl(data, i)" :class="{ active: (selectData != null && selectData.userId == data.userId)}">
                      <td>{{ visibleTotal - (size * currentPage + i) }}</td>
                      <td>{{data.isJoin ? $t('site-monitoring-record-member-list-status-true-name') : $t('site-monitoring-record-member-list-status-false-name') }}</td>
                      <td>{{data.departmentName}}</td>
                      <td>{{data.name}}</td>
                      <td>{{getMask(data.phone)}}</td>
                      <td>{{data.joinCount}}</td>
                    </tr>
                  </table>
                </div>
                <div class="pagination" >
                  <el-pagination
                      small
                      layout="prev, pager, next"
                      :total="visibleTotal"
                      :page-size="size"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage + 1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="box infoWrap" style="width: calc(30% - 2px); height: 100%; min-height: 480px;">
              <h2 class="bold">
                {{ $t("title-detail-info") }}
              </h2>
              <table>
                <colgroup>
                  <col style="width: 25%;"/>
                  <col style="width: 75%;"/>
                </colgroup>
                <tr>
                  <td>
                    <p class="bold">{{ $t("user-data-name") }}</p>
                  </td>
                  <td>{{selectData == null ? '-' : selectData.name}}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("user-data-dept-name") }}</p>
                  </td>
                  <td>{{selectData == null ? '-' : selectData.departmentName}}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("user-data-email") }}</p>
                  </td>
                  <td>{{selectData == null || selectData.userLoginId == undefined ? '-' : selectData.userLoginId}}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("user-data-phone-num") }}</p>
                  </td>
                  <td>{{selectData == null ? '-' : getMask(selectData.phone)}}</td>
                </tr>
                <tr>
                  <td><p class="bold">{{ $t("user-data-check-in-num") }}</p></td>
                  <td>{{selectData == null ? '-' : selectData.joinCount}}</td>
                </tr>
                <tr>
                  <td><p class="bold">{{ $t("user-data-status") }}</p></td>
                  <td>{{selectData == null ? '-' :  selectData.isJoin ? $t('site-monitoring-record-member-list-status-true-name') : $t('site-monitoring-record-member-list-status-false-name') }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: center; border: none;">
                    <div v-if="selectData != null" >
                      <img src="@/assets/images/img_user_profile.jpg" alt="썸네일"
                           style="width: auto; height: auto; max-height: 168px; border: none; margin: auto; padding:15px; "/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--    이동 기록 -->
      <div class="contents" v-if="visible == showType[1]" style="padding:0;">
        <div class="one">
          <div class="boxCol2-2 flexB">
            <div class="box" style="width: calc(45% - 2px); height: 100%; min-height: 480px; border: none; padding: 0;">
              <!-- <h2 class="bold">{{ $t("site-view-data-location-Player") }}</h2>
              <div style="position: relative">
                            <div v-if="locationSize == 0" style="z-index:4; margin-top: 25%; top: 20%; font-size: 2em; right: 25%; left: 25%; line-height: 20; position: absolute; color: white; text-align: center; background-color: rgba(30, 30, 30, 0.8);">
                              {{$t("popup-location-not-found")}}
                            </div>
                          </div>-->


              <div class="mapWrap" style="width: 100%">
                <div class="map" style="min-height: 394px">
                  <Map style="width: 100%; height: 100%; position: absolute;" ref="map" :isRightLngLat=true :loadMapName="loadMap" @play-marker-lng-lat="playMarkerLngLat"/>
                  <span v-if="locationSize == 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                  {{$t("popup-location-not-found")}}
                </span>
                  <div class="box mapVodInfo" >
                    <div>
                      <div class="flex" style="justify-content: center;">
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="DataName bold">위도</span>
                          <span class="vodData">{{lat == null ? 'N/A' : `${lat.toFixed(9)}`}}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="DataName bold">경도</span>
                          <span class="vodData">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important; margin: auto 0; padding-left: 10px;" :style="accountId == 'komipo'? 'visibility: visible':'display:none'">
                          <img src="@/assets/images/icon_svg/ic_heart_rate.svg" style="width: 2rem; height: 2rem;"/>
                          <span class="vodData" style="line-height: 1.8rem;">{{ this.heartRate == null ? 'N/A' : `${this.heartRate} bpm` }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="deviceInfo">
                <div class="progress-bar">
                  <input style="width: 100%; height: 3px;" type="range" id="volume" name="volume" min="0" v-model="progressbarValue" :max="progressbarTotal">
                  <img :src="!isMapPlay ? require('@/assets/images/icon_svg/ic-play.svg') : require('@/assets/images/icon_svg/ic-pause.svg')" alt="icon" @click="onMapPlay">
                  <span class="first">{{ secConvert(progressbarValue) }}</span>
                  <span class="first">/</span>
                  <span class="end">{{ secConvert(progressbarTotal) }}</span>
                </div>
              </div>

            </div>
            <div class="tableWrap box" style="width: calc(55% - 2px); height: 100%; min-height: 480px;  border: none; padding: 0;">
              <div class="tableBox" >
                <div style="min-height: 453px;">
                  <table style="table-layout: fixed;">
                    <colgroup>
                      <col style="width: 5%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 20%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 10%;"/>
                    </colgroup>
                    <tr>
                      <th>No</th>
                      <th> {{ $t("user-data-start-time") }}</th>
                      <th> {{ $t("user-data-end-time") }}</th>
                      <th> {{ $t("user-data-working-time") }}</th>
                      <th> {{ $t("user-data-dept-name") }}</th>
                      <th> {{ $t("user-data-name") }}</th>
                      <th> {{ $t("user-data-check-out-memo") }}</th>
                    </tr>
                    <tr v-if="visibleTotal == 0">
                      <td colspan="7" style="text-align: center;">
                        {{ $t("search-not-found") }}
                      </td>
                    </tr>
                    <tr v-for="(data, i) in visibleList" :key="i" @click="handleSelectEl(data, i)" :class="{ active: selectEl == (data.deviceLogId == undefined ? data.workerId : data.deviceLogId) + '_' + i }">
                      <td>{{ visibleTotal - (size * currentPage + i) }}</td>
                      <td>
                        {{ moment(data.checkInDt).format("YYYY-MM-DD HH:mm") }}
                      </td>
                      <td>
                        {{ moment(data.checkOutDt).format("YYYY-MM-DD HH:mm") }}
                      </td>
                      <td>
                        {{ logTimeToJoinTime(data.checkInDt, data.checkOutDt) }}
                      </td>
                      <td>
                        {{data.deptInfoName}}
                      </td>
                      <td>
                        {{data.name }}
                      </td>
                      <td @click="$event.cancelBubble = true">
                        <a @click="openPop(1, data)" v-if="data.memo != null && data.memo != ''">
                          <img src="@/assets/images/icon_svg/ic_calendar.svg" alt="icon"/>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="pagination">
                  <el-pagination
                      small
                      layout="prev, pager, next"
                      :total="visibleTotal"
                      :page-size="size"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage + 1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="visiblePop == 1" class="dim on">
          <!-- <div class="dim on"> -->
          <CheckOutMemo @close="close" :memo = "selectMemo"/>
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.mapVodInfo{
  width: 360px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
}
.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
  margin-left: 5px;
}
.DataName{
  color: #6ebeff;
  font-size: 1.2rem;
}
.progress-bar {
  width: 100%;
  height: 50px;
  position: relative;
  bottom: 0;
//margin: -61px 0 0;
  padding: 3.8px 19.8px 3px 18.6px;
  background-color: rgba(30, 30, 30, 0.5);
  text-align: right;
}
.progress-bar > img {
  margin-top: 4px;
  float: left;
}
.progress-bar > div {
  display: contents;
}
.progress-bar > span.first {
  display:inline-block;
  padding-top: 4px;
  color : white;
  margin-right: 10px;
}
.progress-bar > span.end {
  display:inline-block;
  padding-top: 4px;
  margin-top: 4px;
  color : white;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import { fetchPointList, getPointTypeName } from "@/api/point";
import { fetchEvent } from "@/api/event";
import {fetchEventJoinList} from "@/api/dashboard";
import {fetchWorkerLogList} from "@/api/worker";
import { fetchLocation } from "@/api/location";
import { format } from "@/mixins/format";

import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

import { baseUrl } from "@/config/env";
import CheckOutMemo from "@/components/pop/CheckOutMemo";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";

export default {
  name: "Home",
  components: { Map, Datepicker, CheckOutMemo },
  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
    progressbarValue : function(value){
      this.$refs.map.onPlayUpdatePosition(value);
    },
    isMapPlay : function(value){
      if(value) {
        this.interval = setInterval(() => {
          if(this.progressbarValue == this.progressbarTotal) {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }
          this.progressbarValue =  Number(this.progressbarValue) + 1;
        }, 1000);
      } else {
        if(this.interval != null) {
          clearInterval(this.interval);
        }
      }
    },
  },
  data() {
    return {
      moment: moment,
      isLoading:false,
      visiblePop: false,
      visible: "JOIN",
      showType: ["JOIN", "LOG"],
      eventInfo: null,
      baseUrl: baseUrl,
      loadMap : 'kakao',

      interval: null,
      selectEl: null,
      ko: ko,

      visibleList: [],
      visibleTotal : 0,
      selectData : null,
      location: [],
      locationSize : null,
      //이동기록 변수
      progressbarTotal: 0,
      progressbarValue : 0,
      isMapPlay : false,
      selectMemo : null,

      //검색 조건
      startYmd: null,
      endYmd: null,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      statuses: null,
      keyword: null,

      lng:null,
      lat:null,

      heartRate: null,
      accountId: null,
    };
  },
  async mounted() {
    this.getEvent();
    this.handleTab(this.showType[0]);
    this.getEventJoinWorkerList();
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 12 });

    this.accountId = localStorage.getItem("accountId");
  },
  methods: {
    playMarkerLngLat(lng, lat, index) {
      index;
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.heartRate = locationData.heartRate;
      }
      this.$refs.map.onSetLngLat(lng,lat);
      this.lng = lng;
      this.lat = lat;
    },

    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        this.$refs.map.onShowButtonTypeChange();
        //this.$refs.map.onShowLngLat();
        if(this.$refs.map != undefined) {
          if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
        this.getEventPoint();

        if(this.selectData != null) {
          var dateFrom = this.selectData.checkInDt
          var dateTo = this.selectData.checkOutDt
          this.getLocation(this.selectData, dateFrom, dateTo);
        }
      })
    },
    openPop(id, data) {
      this.visiblePop = id;
      this.selectMemo = data.memo;
    },
    close() {
      this.visiblePop = false;
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    logTimeToJoinTime(checkInDt, checkOutDt){
      var inDate = new Date(moment(checkInDt));
      var outDate = new Date(moment(checkOutDt));

      var time = outDate.getTime() - inDate.getTime();
      if(time < 0){ //time 이 음수일 때 발생하는 오류 방지
        time = 0;
      }
      return this.secConvert(Math.floor(time/ 1000));
    },
    secConvert(seconds) {
      var hour = parseInt(seconds/3600) < 10 ? '0'+ parseInt(seconds/3600) : parseInt(seconds/3600);
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return hour+":"+min+":" + sec;
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if(this.$refs.map != undefined) {
            if(res.data.data.lng != null && res.data.data.lng != "" && res.data.data.lat != null && res.data.data.lat != "") {
              this.$refs.map.onLngLatToMoveCenter(res.data.data.lng, res.data.data.lat)
            } else {
              this.$refs.map.onAddressToMoveCenter(res.data.data.address);
            }
          }
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val - 1;
      if(this.visible == this.showType[0]){
        this.getEventJoinWorkerList();
      } else {
        this.getWorkerLogList();
      }
    },
    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "all";
    },
    onMapPlay(){
      /*이동기록 확인 팝업*/
      switch (this.locationSize){
        case 0 :
          alert("이동기록이 없을 시 재생할 수 없습니다.");
          break;
        case null :
          alert("이력 선택 후 재생해 주세요.");
          break;
        default :
          this.isMapPlay = ! this.isMapPlay;
          break;
      }
    },

    handleTab(id) {
      this.visible = id;
      this.visibleList.splice(0,1);
      this.selectData = null;
      this.currentPage = 0;

      if(this.showType[0] == id) {
        this.joinHandleTab();
      } else {
        this.initMapData(this.currentLang);
        this.logHandleTab();
      }
    },
    joinHandleTab(){
      this.getEventJoinWorkerList()
    },
    async logHandleTab() {
      this.isLoading = true;
      this.isMapPlay = false;
      await this.getWorkerLogList();

    },

    handleSelectEl(data, i) {
      if(this.showType[0] == this.visible) {
        this.joinHandleSelectEl(data, i);
      } else {
        this.logHandleSelectEl(data, i);
      }
    },
    joinHandleSelectEl(data){
      if(this.selectData != null && this.selectData.userId == data.userId) {
        this.selectData = null;
      } else {
        this.selectData = data;
        this.selectData.userLoginId = data.userLoginId;
        this.selectData.isJoin = data.isJoin;
      }
    },
    logHandleSelectEl(data, i){
      this.isMapPlay = false;
      this.$refs.map.onClearMapData();
      this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
      this.getEventPoint();
      var id = (this.visible == this.showType[0] ? data.deviceLogId : data.workerId) + "_" + i;
      if(this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }
      if(this.visible != this.showType[0] && this.selectEl != null){
        var dateFrom = data.checkInDt
        var dateTo = data.checkOutDt
        this.getLocation(this.selectData, dateFrom, dateTo);
      } else {
        this.getLocation(this.selectData);
      }
    },

    getEventJoinWorkerList(){
      let params = {
        eventId : this.eventId,
        pageSize : this.size,
        pageNumber : this.currentPage,
        keywordType: this.keywordType,
        keyword: this.keyword
      };
      fetchEventJoinList('worker', params).then((res) => {
        if (res.data.result == 0) {
          this.visibleTotal = res.data.data.total
          this.visibleList = res.data.data.content
        }
      });
    },
    logSearch(){
      this.currentPage = 0;
      if(this.visible === this.showType[0]){
        this.getEventJoinWorkerList();
      }else if(this.visible === this.showType[1]){
        this.getWorkerLogList();
      }
    },
    async getWorkerLogList(){
      let params = {
        eventId : this.eventId,
        pageSize : this.size,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchWorkerLogList(params).then((res) => {
        if (res.data.result == 0) {
          this.visibleTotal = res.data.data.total
          this.visibleList = res.data.data.content
        }
      });
    },
    async getLocation(data, dateFrom, dateTo) {
      this.progressbarTotal = 0;
      this.progressbarValue = 0;
      this.locationSize = null;
      if(data == null) {
        return;
      }

      let params = {
        refId: this.visible == this.showType[0] ? data.deviceLogId : data.workerId,
        eventId : this.eventId,
        'localDateRange.from' : null,
        'localDateRange.to' : null,
      };

      if(dateFrom != undefined && dateTo != undefined) {
        params['localDateRange.from'] = moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss.SSS");
        params['localDateRange.to'] = moment(dateTo).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }
      fetchLocation(params).then((res) => {

        if(res.data.result == 0) {
          this.locationSize = res.data.data.content.length;
          if(res.data.data.content.length > 0) {
            this.location = res.data.data.content.sort(function(a, b) {
              return a.index - b.index;
            })

            this.playMarkerLngLat( res.data.data.content[0].lng , res.data.data.content[0].lat, 0);

            var first = new Date(moment(res.data.data.content[0].time));
            var last = new Date(moment(res.data.data.content[res.data.data.content.length - 1].time));
            var maxValue = last.getTime() - first.getTime();
            this.progressbarTotal = maxValue/ 1000;

            var firstData = res.data.data.content[0];
            this.$refs.map.onLngLatToMoveCenter(firstData.lng, firstData.lat)
            var mapLineData = new MapLineData();
            mapLineData.id = this.contentsId;
            mapLineData.locationGroup = res.data.data.content;
            mapLineData.mapDataType = MapDataType.ID_STREAMER;
            this.$refs.map.onPlayCreateData(mapLineData);
          }
        } else {
          this.locationSize = 0;
        }
      });
    },
    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },

  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
